<script setup>
import { getPolls, getSendPoll } from './poll';
import { useI18n } from 'vue-i18n';
import { ref } from 'vue';

const { t } = useI18n();
const variants = [1,2,3,4];

const polls = ref([]);

await getPolls(polls);

const pollModel = ref(variants.reduce((acc, v) => {
    acc[`otvet_${v}`] = false;
    return acc;
}, {}));

const sendPoll = getSendPoll(pollModel, variants, t('polls.messages.confirmed.singular'));

</script>

<template>
    <div class="language light-theme" v-if="polls.length > 0">
        <div class="container">
            <form class="language__wrapper" @submit.prevent="sendPoll(poll)" v-for="poll in polls">
                <img src="@/assets/img/petern.svg" class="language__bg" alt="" />
                <p class="language__title">{{ poll.questions }}</p>
                <div class="language__block">
                    <template v-for="i in variants">
                        <div class="language__label" v-if="poll[`variant_${i}`]">
                            <input 
                                type="checkbox" 
                                :id="`otvet_${i}`" 
                                :value="poll[`otvet_${i}`] + 1" 
                                v-model="pollModel[`otvet_${i}`]"
                            />
                            <div class="language__check">
                                <span>
                                    <img src="@/assets/img/check.svg" alt="" />
                                </span>
                            </div>
                            <label :for="`otvet_${i}`">{{ poll[`variant_${i}`] }}</label>
                        </div>
                    </template>
                </div>
                <button type="submit" class="language__btn">
                    <i18n-t keypath="polls.action.vote.singular" scope="global">...</i18n-t>
                </button>
            </form>
        </div>
    </div>
    <div class="language light-theme" v-else></div>
</template>