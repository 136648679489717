import axios from "axios";
import { tryCatch } from "@/utils/handle";

export const getPolls = async (pollsRef) => {
    await tryCatch(null, async () => {
        const response = await axios.get("/api/v1/get/oprosnik/");

        pollsRef.value = response.data;
    });
};

export const getSendPoll = async (refs, variants, confirmed_message) => async (item) => {
    await tryCatch(null, async () => {
        const payload = variants.reduce((acc, v) => {
            acc[`otvet_${v}`] = item[`otvet_${v}`] + (+refs[`otvet_${v}`].value || 0);

            return acc;
        }, {});

        await axios.put(`/api/v1/put/oprosnik/${item.id}`, payload);

        for (let v of variants) {
            refs[`otvet_${v}`].value = false;
        }

        alert(confirmed_message);
    });
};