import { createRouter, createWebHistory } from 'vue-router'

import HomeView from '../views/HomeView.vue'
import About from '../views/About/About.vue'

import Radio from '../views/Radio/Radio.vue'
import Catalog from '../views/Catalog/MainCatalog.vue'
import VideoDetail from '../views/Video/VideoDetail.vue'

import MainRadio from '../views/Catalog/MainRadio.vue'

import RadioProgramm from '../views/RadioProgramms/RadioProgramm.vue'

import Tv from '../views/Tv/Tv.vue'

import Search from '../views/Search/Search.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },

  {
    path: '/about',
    name: 'about',
    component: About
  },

  {
    path: '/radio',
    name: 'Radio',
    component: Radio
  },

  {
    path: '/radio/program',
    name: 'RadioProgramm',
    component: RadioProgramm
  },

  {
    path: '/catalog',
    name: 'Catalog',
    component: Catalog
  },

  {
    path: '/search',
    name: 'Search',
    component: Search
  },

  {
    path: '/radio/catalog',
    name: 'MainRadio',
    component: MainRadio
  },


  {
    path: '/tv',
    name: 'Tv',
    component: Tv
  },

  {
    path: '/video/item/:pk',
    name: 'VideoDetail',
    component: VideoDetail
  },  

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
      document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
  }
})

export default router
