// ! moment.js locale configuration
// ! locale: Russian Latin [ru-latn]
// ! author: Okttastudio<v@oktta.ru> : https://github.com/okttastudio

;(function(global, factory) {
    typeof exports === 'object' && typeof module !== 'undefined' && typeof require === 'function' ?
    factory(require('moment')) : typeof define === 'function' && define.amd ? define(['moment'], factory) :
    factory(global.moment);
}(this, (function(moment) { 'use strict';

    // ! moment.js locale configuration
    function plural(word, num) {
        var forms = word.split('_');

        return num % 10 === 1 && num % 100 !== 11 ? forms[0] : (
            num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20) ? forms[1] : forms[2]
        );
    }

    function relativeTimeWithPlural(number, withoutSuffix, key) {
        var format = {
            ss: withoutSuffix ? 'sekunda_sekundy_sekund' : 'sekundu_sekundy_sekund',
            mm: withoutSuffix ? 'minuta_minuty_minut' : 'minutu_minuty_minut',
            hh: 'chas_chasa_chasov',
            dd: 'den_dnia_dnei',
            ww: 'nedelia_nedeli_nedel\'',
            MM: 'miesec_mieseca_miesecev',
            yy: 'god_goda_let',
        };

        if (key === 'm') {
            return withoutSuffix ? 'minuta' : 'minutu';
        }

        return number + ' ' + plural(format[key], +number);
    }

    var monthsParse = [
        /^ianv/i,
        /^fev/i,
        /^mar/i,
        /^apr/i,
        /^mai/i,
        /^iiun/i,
        /^iiul/i,
        /^avg/i,
        /^sen/i,
        /^okt/i,
        /^noia/i,
        /^dek/i,
    ];

    // http://new.gramota.ru/spravka/rules/139-prop : § 103
    // Sokrashheniia mesiacev http://new.gramota.ru/spravka/buro/search-answer?s=242637
    // CLDR data:             http://www.unicode.org/cldr/charts/28/summary/ru.html#1753
    var ruLatn = moment.defineLocale('ru-latn', {
        months: {
            format: 'ianvaria_fevralia_mart_aprelia_maia_iiunia_iiulia_avgusta_sentiabria_oktiabria_noiabria_dekabria'.split(
                '_'
            ),
            standalone: 'ianvar_fevral_mart_aprel_mai_iiun_iiul_avgust_sentiabr_oktiabr_noiabr_dekabr'.split('_'),
        },
        monthsShort: {
            // CLDR data:             http://www.unicode.org/cldr/charts/28/summary/ru.html#1753
            format: 'ian._fev._mar._apr._mai._iiun._iiul._avg._sen._okt._noiab._dek.'.split('_'),
            standalone: 'ian._fev._mar._apr._mai._iiun._iiul._avg._sen._okt._noiab._dek.'.split('_'),
        },
        weekdays: {
            standalone: 'voskresenie_ponedelnik_vtornik_sreda_chetverg_piatnitsa_subbota'.split(
                '_'
            ),
            format: 'voskresenie_ponedelnik_vtornik_sredu_chetverg_piatnitsu_subbotu'.split(
                '_'
            ),
            isFormat: /\[ ?[Vv] ?(?:proshluiu|sledushchuiu|etu)? ?] ?dddd/
        },
        weekdaysShort: 'vs_pn_vt_sr_cht_pt_sb'.split('_'),
        weekdaysMin: 'vs_pn_vt_sr_cht_pt_sb'.split('_'),
        monthsParse: monthsParse,
        longMonthsParse: monthsParse,
        shortMonthsParse: monthsParse,
        // Polnye nazvaniia s padezhami, po tri bukvy, dlia nekotorykh formatov, po 4 bukvy, sokrashheniia s tochkoi i bez tochki
        monthsRegex: 
            /^(ianvar[ia]?|ianv\.?|fevral[ia]?|fevr?\.?|marta?|mar\.?|aprel[ia]?|апр\.?|maia?|iiun[ia]?|iiun\.?|iiul[ia]?|iiul\.?|avgusta?|avg\.?|sentiabr[ia]?|sent?\.?|oktiabr[ia]?|okt\.?|noiabr[ia]?|noiab?\.?|dekabr[ia]?|dek\.?)/i,

        // kopiia predydushchego
        monthsShortRegex: 
            /^(ianvar[ia]?|ianv\.?|fevral[ia]?|fevr?\.?|marta?|mar\.?|aprel[ia]?|апр\.?|maia?|iiun[ia]?|iiun\.?|iiul[ia]?|iiul\.?|avgusta?|avg\.?|sentiabr[ia]?|sent?\.?|oktiabr[ia]?|okt\.?|noiabr[ia]?|noiab?\.?|dekabr[ia]?|dek\.?)/i,

        // Vyrazhenie, kotoroe sootvetstvuet tolko sokraschennym formam
        monthsStrictRegex: /^(ianv\.|fevr?\.|mar[t.]|apr\.|maia?|iiun[ia.]?|iiul[ia.]?|avg\.|sent?\.|okt\.|noiab?\.|dek\.)/i,
        longDateFormat: {
            LT: 'H:mm',
            LTS: 'H:mm:ss',
            L: 'DD.MM.YYYY',
            LL: 'D MMMM YYYY g.',
            LLL: 'D MMMM YYYY g., H:mm',
            LLLL: 'dddd, D MMMM YYYY g., H:mm',
        },
        calendar: {
            sameDay: '[Segodnia, v] LT',
            nextDay: '[Zavtra, v] LT',
            lastDay: '[Vchera, v] LT',
            nextWeek: function(now) {
                var day = this.day();

                if (now.week() !== this.week()) {
                    switch (day) {
                        case 0: return '[V sledushchuiu] dddd, [v] LT';
                        case 1:
                        case 2:
                        case 4: return '[V sledushchii] dddd, [v] LT';
                        case 3:
                        case 5:
                        case 6: return '[V sledushchuiu] dddd, [v] LT';
                    }
                }

                if (day === 2) {
                    return '[Vo] dddd, [v] LT';
                }

                return '[V] dddd, [v] LT';
            },
            lastWeek: function(now) {
                var day = this.day();
                if (now.week() !== this.week()) {
                    switch (day) {
                        case 0: return '[V proshloe] dddd, [v] LT';
                        case 1:
                        case 2:
                        case 4: return '[V proshlyi] dddd, [v] LT';
                        case 3:
                        case 5: 
                        case 6: return '[V proshluiu] dddd, [v] LT';
                    }
                }

                if (day === 2) {
                    return '[Vo] dddd, [v] LT';
                }

                return '[V] dddd, [v] LT';
            },
            sameElse: 'L',
        },
        relativeTime: {
            future: 'cherez %s',
            past: '%s nazad',
            s: 'neskolko sekund',
            ss: relativeTimeWithPlural,
            m: relativeTimeWithPlural,
            mm: relativeTimeWithPlural,
            h: 'chas',
            hh: relativeTimeWithPlural,
            d: 'den',
            dd: relativeTimeWithPlural,
            w: 'nedelia',
            ww: relativeTimeWithPlural,
            M: 'mesets',
            MM: relativeTimeWithPlural,
            y: 'god',
            yy: relativeTimeWithPlural,
        },
        meridiemParse: /nochi|utra|dnia|vechera/i,
        isPM: function(input) {
            return /^(dnia|vechera)$/.test(input);
        },
        meridiem: function(hour, minute, isLower) {
            if (hour < 4) return 'nochi';
            if (hour < 12) return 'utra';
            if (hour < 17) return 'dnia';
            return 'vechera';
        },
        daysOfMonthOrdinalParse: /\d{1,2}-(i|go|ia)/,
        original: function(number, period) {
            var periods = {
                'M': number + '-i',
                'd': number + '-i',
                'DDD': number + '-i',
                'D': number + '-go',
                'w': number + '-ia',
                'W': number + '-ia',
            };

            return periods[period] || number;
        },
        week: {
            dow: 1, // Monday is the first day of the week.
            doy: 4, // The week that contains Jan 4th is the first week of the year.
        },
    });

    return ruLatn;
})));