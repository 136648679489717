import { createStore } from 'vuex'

export default createStore({
  state: {
    cart: {
        items: [],
    },
    isAuthenticated: false,
    token: '',
    nickname: '',
    rtime: 0,
    radio: {},
    isLoading: false,
    theme: false,
  },
  mutations: {
    initializeStore(state) {
      if (localStorage.getItem('cart')) {
        state.cart = JSON.parse(localStorage.getItem('cart'))
      } else {
        localStorage.setItem('cart', JSON.stringify(state.cart))
      }

      if (localStorage.getItem('token')) {
          state.token = localStorage.getItem('token')
          state.isAuthenticated = true
      } else {
          state.token = ''
          state.isAuthenticated = false
      }

      if (localStorage.getItem('theme')) {
          state.theme = localStorage.getItem('theme')
      } else {
          state.theme = false
      }

      if (localStorage.getItem('radio')) {
        state.radio = JSON.parse(localStorage.getItem('radio'))
      } else {
        localStorage.setItem('radio', JSON.stringify(state.radio))
      }

      if (localStorage.getItem('nickname')) {
        state.nickname = localStorage.getItem('nickname')
      } else {
        state.nickname = ''      
      }

      if (localStorage.getItem('rtime')) {
        state.rtime = localStorage.getItem('rtime')
      } else {
        state.rtime = 0      
      }

    },
    addToCart(state, item) {
      const exists = state.cart.items.filter(i => i.product.id === item.product.id)
      if (exists.length) {
        exists[0].quantity = parseInt(exists[0].quantity) + parseInt(item.quantity)
      } else {
        state.cart.items.push(item)
      }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
    setIsLoading(state, status) {
      state.isLoading = status
    },
    setRadio(state, radio) {
        state.radio = radio
        localStorage.setItem('radio', JSON.stringify(state.radio))
    },
    setTheme(state, theme) {
        state.theme = theme
    },  
    setNickname(state, nickname) {
        state.nickname = nickname
    },  
    setRtime(state, rtime) {
        state.rtime = rtime
    },  
    removeNickname(state) {
        state.nickname = ''
    },  
    removeRadio(state) {
        state.radio = {}
    },
    removeTheme(state) {
        state.theme = false
    },  
    setToken(state, token) {
        state.token = token
        state.isAuthenticated = true
    },  
    removeToken(state) {
        state.token = ''
        state.isAuthenticated = false
        state.anketaCheck = false
    },
    clearCart(state) {
      state.cart = { items: [] }

      localStorage.setItem('cart', JSON.stringify(state.cart))
    },
  },
  actions: {
  },
  modules: {
  }
})
