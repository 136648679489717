<script setup>
import { inject, toRef } from 'vue';
import { useRouter } from 'vue-router';

const props = defineProps({
    isMobile: {
        type: Boolean,
        required: true,
    },
});

const router = useRouter();
const query = defineModel('query', {
    default: ""
});

const isMobile = toRef(props, 'isMobile');
const search = inject('search');
const changeSearch = inject('changeSearch');
const Menu = inject('Menu');

function searchActive() {   
    changeSearch(true);
}

function searchInactive() {
    changeSearch(false);
}

function navigate() {
    if (!query.value) return false;
    router.push({ name: 'Search', query: { q: query.value } });

    return true;
}

function searchSubmit() {
    if (!navigate()) return;

    searchInactive();
    Menu();
    query.value = "";
}

</script>

<template>
    <div class="nav__search_container">
        <a @click="searchActive" class="nav__search" v-if="!isMobile || !search">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
               <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M14.3849 15.4457C11.7346 17.5684 7.8552 17.4013 5.39842 14.9445C2.76238 12.3085 2.76238 8.03464 5.39842 5.3986C8.03445 2.76256 12.3083 2.76256 14.9444 5.3986C17.4011 7.85538 17.5682 11.7348 15.4456 14.3851L20.6012 19.5407C20.8941 19.8336 20.8941 20.3085 20.6012 20.6014C20.3083 20.8943 19.8334 20.8943 19.5405 20.6014L14.3849 15.4457ZM6.45908 13.8839C4.40882 11.8336 4.40882 8.50951 6.45908 6.45926C8.50933 4.40901 11.8334 4.40901 13.8837 6.45926C15.9324 8.50801 15.9339 11.8287 13.8882 13.8794C13.8867 13.8809 13.8852 13.8823 13.8837 13.8839C13.8822 13.8854 13.8807 13.8869 13.8792 13.8884C11.8286 15.9341 8.50783 15.9326 6.45908 13.8839Z"
                  fill="black" />
            </svg>

            <i18n-t keypath="search.title.singular" tag="span" v-if="isMobile"></i18n-t>
        </a>
        <form @submit.prevent="searchSubmit" class="nav__input">
            <input type="text" @keypress="searchActive" v-model="query" :placeholder="$t('search.placeholder.query.singular')"/>
            <div class="nav__input-close" @click="searchInactive">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M18 18L6 6" stroke="#262F44" stroke-width="1.5" stroke-linecap="round"
                     stroke-linejoin="round" />
                  <path d="M18 6L6 18" stroke="#262F44" stroke-width="1.5" stroke-linecap="round"
                     stroke-linejoin="round" />
               </svg>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.nav__input-close {
    display: flex;
    justify-content: center;
}

.nav__search_container {
    display: flex;
    align-items: center;
}

@media (max-width: 930px) {
    .nav__search {
        width: 100%;
        display: flex;
        gap: 0.625rem;
        margin: 0 !important;
        justify-content: flex-start;
    }
}

@media(max-width: 650px) {
    .nav {
        &__search {
            margin-right: 10px;
        }
    }
}
</style>