<template>
      <div 
          class="slider-film light-theme" 
      >
        <swiper
          class="slider-film__wrapper"
          :modules="modules"
          :navigation="{
              prevEl: prev,
              nextEl: next,
          }"
          :centeredSlides="true"
          :loop="true"
          :autoplay="autoplay"
          :breakpoints="swiperOptions.breakpoints"
        >
        <div ref="prev" class="slick-prev slick-arrow">prev</div>
            <swiper-slide class="slider-film__slide" v-for="list, index in slideShow" @click="GoTo(list.link)">
                <div class="slider-film__slide_wrapper">
                    <img :src="list.get_preview" v-if="list.get_preview">
                    <img src="@/assets/img/slider_1.jpg" v-else>
                    <div class="slider-film__tag">
                        <p v-for="item in list.tag" :class="{'grey': item.color == 0, 'blue': item.color == 1}">{{ item.name }}</p>
                    </div>
                    <div class="slider-film__info">
                        <!-- <p class="slider-film__time">Сегодня, 20:00</p> -->
                        <p class="slider-film__name">{{ list.name }}</p>
                    </div>
                </div>
            </swiper-slide>
            <div ref="next" class="slick-next slick-arrow">next</div>
        </swiper>
    </div>
</template>
<style>
    .list-film__slide {
       
    }

    .slider-film__slide {
        cursor: pointer;
    }
</style>
<script>
  import { ref } from 'vue';
  // import axios from 'axios'
  // import moment from 'moment';
  import SwiperCore, { Navigation, Autoplay } from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  SwiperCore.use(Autoplay);

  export default {
    name: 'HomeSwiper',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
          breakpoints: {       
            576: {       
               slidesPerView: 2,
               spaceBetween: 5    
            }, 
            700: {       
               slidesPerView: 1.7,
               spaceBetween: 5    
            },                                        
          }   
       },
       cat_lists: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      slideShow: {
        type: Array,
        default: []
      }
    },
    methods: {
        GoTo(redirect) {
            this.$router.push(redirect)
        },
        // async GetCategoryList() {
        //   await axios
        //   .get(`/api/v1/get/main/list/`)
        //   .then(response => {
        //       this.cat_lists = response.data
        //       console.log(response.data)
        //   })
        //   .catch(error => {
        //     console.log(error)
        //   })           
        // },     
        // CorDate(date) {
        //       moment.locale('ru');
        //       const datasort = moment(String(date)).format('DD MMMM YYYY')
        //       return datasort
        // },
    },
    setup() {
       const prev = ref(null);
       const next = ref(null);
      return {
        modules: [Navigation],
        prev,
        next,
      };
    },
    created() {
        // this.GetCategoryList()
    }
  };
</script>