export async function tryCatch(handler = null, fn, ...args) {
    if (typeof fn !== 'function') {
        throw new Error(`fn must be a function`);
    }

    try {
        return await fn(...args);
    } catch(e) {
        if (typeof handler === 'function') {
            return await handler(e);
        } else {
            console.log(Object.keys(e), e.message);
        }
    }
};
