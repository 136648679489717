<template>
   <Header />

   <section class="tv">
      <div class="container">
         <TvNavigation 
            :title="$t('teleprogram.title.singular')"
            :tv="tvFiltering"
            :indexSwiper="indexSwiper" 
            :currentId="current_tv.id"
            v-on:active="current_tv = $event" 
         />
         <div class="tv__wrapper">
            <div v-for="list, index in videos" class="tv__item">
               <div class="tv__time">
                  <p class="tv__time_text" v-if="list.time_to_start">
                     {{ CorTime(list.time_to_start) }}
                  </p>
                  <p class="tv__time_text" v-else>
                     ***
                  </p>
               </div>
               <div class="tv__prev" @click="RedirectNext(list)" :class="{ 'default-cursor': !isRedirecting(list) }">
                  <img v-if="list.video.get_preview" :src="list.video.get_preview" alt="">
                  <img v-else src="@/assets/img/tv_clear.jpg">
                  <div class="tv__prev_play">
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                           fill="var(--white)" />
                     </svg>
                  </div>
               </div>
               <div class="tv__info">
                  <p class="tv__info_name">{{ list.video.name }}</p>
                  <!-- <p class="tv__info_har">Фильм</p> -->
                  <p class="tv__info_text" :class="{ 'active': currentRead == index }">{{ list.video.description }}</p>
                  <!-- <p class="tv__info_btn" :class="{ 'active': currentRead == index }" @click="CurrentClose()"
                     v-if="currentRead == index">
                     <span :class="{ 'active': currentRead == index }">Свернуть</span>
                     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#262F44" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </p>
                  <p class="tv__info_btn" @click="CurrentOpen(index)" v-else>
                     <span :class="{ 'active': currentRead == index }">Читать полностью</span>
                     <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#262F44" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                     </svg>
                  </p> -->
               </div>
            </div>
         </div>
      </div>
   </section>
   <Donate />
</template>

<style lang="scss">
.tv__prev {
   cursor: pointer;

   &.default-cursor {
      cursor: default;
   }
}
</style>

<script>
import axios from 'axios'
import moment from 'moment';
import Header from '@/components/Header/Header'
import Donate from '@/components/Donate/Donate'
import TvNavigation from '@/components/Swiper/TvNavigation'


export default {
   name: 'Tv',
   data() {
      return {
         tv: [],
         tvFiltering: [],
         current_tv: {},
         videos: [],

         currentRead: null,
         teleTime: "00:00:00",

         indexSwiper: 0,
         todayDate: 0,
         isFuture: false,
         isToday: false,
      }
   },
   methods: {
      goTo(redirect) {
         this.$router.push(redirect)
      },
      async GetTeleprogramList() {
         await axios
            .get(`/api/v1/get/teleprogram/list/`)
            .then(response => {
               this.tv = response.data

               // console.log(response.data)

               this.GetTeleprogramToday()

            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetTeleprogramToday() {
         await axios
            .get(`/api/v1/get/teleprogram/today/`)
            .then(response => {
               this.current_tv = response.data

               this.indexSwiper = this.tv.findIndex(item => item.id === this.current_tv.id);

               if (response.data == 'none') {
                  this.current_tv = this.tv[this.tv.length - 1]
                  this.indexSwiper = this.tv.findIndex(item => item.id === this.tv[this.tv.length - 1].id);
               }

               this.GetFiltering(this.indexSwiper)


            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetTdToday() {
         await axios
            .get(`/api/v1/get/teleprogram/today/`)
            .then(response => {
               this.todayDate = response.data.id
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetFiltering(value) {
         await axios
            .get(`/api/v1/get/teleprogram/list/filter/${value}/`)
            .then(response => {
               this.tvFiltering = response.data
               // console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })
      },

      async GetVideoList(value) {
         if (!value) return;
         await axios
            .get(`/api/v1/get/teleprogram/videos/${value}/`)
            .then(response => {
               this.videos = response.data.results
               console.log(response.data)

               // this.teleTime = response.data[0].teleprogram_id.time_to_start

               // console.log(response.data)

               // for (let q = 0; q < response.data.length; q++) {
               //   const item = response.data[q]

               //   console.log(this.CorTime(this.teleTime, item.video.duration))
               // }     


            })
            .catch(error => {
               console.log(error)
            })
      },
      CurrentOpen(index) {
         this.currentRead = index
      },
      CurrentClose(index) {
         this.currentRead = null
      },
      CorTime(timeValue, durationValue) {
         return moment(timeValue, "HH:mm:ss").format("HH:mm")
      },
      RedirectNext(value) {
         if (!this.isFuture && !this.isToday) {
            // if (this.todayDate > value.teleprogram_id) {
            //    this.$router.push('/video/item/' + value.video.id)
            // } else {
            //    if (this.todayDate == value.teleprogram_id && value.video.draft == 0) {
            //       this.$router.push('/video/item/' + value.video.id)
            //    }
            // }
            // this.$router.push('/video/item/' + value.video.id)
            this.$router.push('/video/item/' + value.video.id)
         } else if (this.isToday) {
            if (value.video.draft == 0) {
               this.$router.push('/video/item/' + value.video.id)
            }
         }

      },
      isRedirecting(value) {
         if ((!this.isFuture && !this.isToday) || (this.isToday && value.video.draft == 0)) {
            return true
         } else {
            return false
         }
      }
   },
   components: {
      Header,
      Donate,
      TvNavigation
   },
   mounted() {
      setTimeout(() => {
         this.GetVideoList(this.current_tv.id)
      }, 500)
   },
   created() {
      moment.locale('ru');
      console.log(moment.duration(365, 'days'))
      this.GetTeleprogramList()
      this.GetTdToday()
   },
   watch: {
      current_tv(value) {
         this.teleTime = value.time_to_start
         this.GetVideoList(this.current_tv.id)



         const tvDate = new Date(value.date_to_start);
         const currentDate = new Date()

         if (tvDate < currentDate) {
            this.isFuture = false
            this.isToday = false
            // return
         } else {
            this.isToday = false
            this.isFuture = true
            // return
         }
         if (tvDate.getFullYear() == currentDate.getFullYear() && tvDate.getMonth() == currentDate.getMonth() && tvDate.getDate() == currentDate.getDate()) {
            this.isFuture = false
            this.isToday = true
            // return
         }
         // else {
         //    this.isToday = false
         //    this.isFuture = true
         //    // return
         // }
         // if (tvDate < currentDate) {
         //    this.isFuture = false
         //    return
         // } else {
         //    this.isFuture = true
         // }
      },
   },
}
</script>