<template>
    <div>
        <h1 v-if="!isMobile" style="margin-bottom: 40px">{{ title }}</h1>
        <div class="tv__bar-wrapper">
            <div class="tv__bar__title">
                <h1 v-if="isMobile">{{ title }}</h1>
                <div :class="{ 'tv__bar__controls': isMobile }">
                    <div ref="prev" class="slick-prev slick-arrow">prev</div>
                    <div ref="next" class="slick-next slick-arrow">next</div>
                </div>
            </div>
            <swiper
                :modules="modules" 
                :breakpoints="{
                    700: {
                        slidesPerView: 7,
                        slidesPerGroup: 1,
                        spaceBetween: 15,
                    }
                }"
                :slidesPerView="3.5"
                :slidesPerGroup="2"
                :initial-slide="indexLive - 1"
                mousewheel 
                :navigation="{
                    prevEl: prev,
                    nextEl: next,
                    disabledClass: 'slick-disabled'
                }" 
                @swiper="onSwiper" 
                class="tv__bar"
            >
                <swiper-slide v-for="list, index in tv" class="tv__bar_item"
                    :class="{ 'active': currentId == list.id, 'tv__bar_dis': 3 > index }" @click="SetCurrent(list)">
                    <p class="tv__bar_day">
                        {{ DateToday(list.date_to_start) }}
                    </p>
                    <p class="tv__bar_date">
                        {{ CorDate(list.date_to_start) }}
                    </p>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<style lang="scss">
.tv__bar__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .tv__bar__controls {
        position: relative;
        display: flex;
        align-items: center;
        gap: 0.625rem;

        & .slick-arrow {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            transform: unset;
            left: unset;
            right: unset;
            opacity: 1;
            font-size: 0;
            background: #82909F4D !important;
            border: none;
            border-radius: 0.75rem;
            transition: all 0.3s;

            &.slick-prev::before {
                transform: rotate(180deg);
            }

            &::before {
                content: "";
                display: block;
                width: 21px;
                height: 21px;
                background: url("@/assets/img/slider_next.svg") center no-repeat;
                background-size: contain;
            }

            &:hover, &:active {
                background: #82909F !important;
                box-shadow: 0px 20px 80px rgba(1, 144, 64, 0.3), inset 0px 1px 0px rgba(255, 255, 255, 0.08);
            }
        }
    }
}

@media (max-width: 720px) {
    .tv__bar__title h1 {
        font-size: 1.75rem;
        line-height: 2.25rem;
        font-weight: 800;
    }
}
</style>

<script>
import { ref, defineComponent, toRefs, computed, watch, onMounted, onUnmounted, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import SwiperCore, { Navigation, Autoplay, Mousewheel } from "swiper";
import { Swiper, SwiperSlide } from 'swiper/vue';
import { formatDate } from '@/utils/datetime';
import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use(Autoplay);

const cat_lists = [];


export default defineComponent({
    name: 'MainCategory',
    emits: ['active'],
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        tv: {
            type: Array,
            default: [],
        },
        currentId: {
            type: Number,
            default: 0,
        },
        indexSwiper: {
            type: Number,
            default: 0,
        },
        title: {
            type: String,
            default: "",
        }
    },
    setup(props, { emit }) {
        const { currentId, tv } = toRefs(props);
        const swiper = ref(null);
        const { locale } = useI18n({
            useScope: 'global',
            inheritLocale: true
        });
        
        const isMobile = ref(window.innerWidth <= 720);

        const updateScreenSize = () => {
            isMobile.value = window.innerWidth <= 720;
        };

        onMounted(() => {
            window.addEventListener('resize', updateScreenSize);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', updateScreenSize);
        });

        const indexLive = computed(() => {
            const i = (tv.value || []).findIndex((item) => item.id === currentId.value);

            return i;
        });

        const CorDate = (date) => {
            const m = isMobile.value;

            const f = `DD MMM${ m ? '' : 'M' }`;

            const dataSort = formatDate(date, f, locale.value).replace('.', '');
            
            return dataSort + (m ? '.' : '');
        };

        const DateToday = (value) => {
            const m = isMobile.value;

            const f = `ddd${ m ? '' : 'd' }`;

            const today = formatDate(value, f, locale.value);
            
            return today + (m ? '.' : '');
        };

        const SetCurrent = (value) => {
            emit('active', value);
        };

        const onSwiper = (instance) => {
            swiper.value = instance;
        };

        watch(indexLive, (value, oldValue) => {
            if (value === oldValue) return;
            if (!swiper.value) return;

            swiper.value.slideTo(value - 1, 0, true);
        });

        const prev = ref(null);
        const next = ref(null);

        return {
            modules: [Navigation, Mousewheel],
            onSwiper,
            prev,
            next,
            cat_lists,
            SetCurrent,
            CorDate,
            DateToday,
            indexLive,
            isMobile
        };
    }
});
</script>