import axios from "axios";
import { tryCatch } from "@/utils/handle";

export const getAllMessages = (refs, options = {}) => async () => {
    await tryCatch(null, async () => {
        const { messages, id } = refs;
        const { chatType = 'teleprogram' } = options;

        const response = await axios.get(`/api/v1/chat/${chatType}/${id.value}/`);

        messages.value = response.data;
    });
};

export const getSendMessage = (refs, options = {}) => async () => {
    await tryCatch(null, async () => {
        const { id, nickname, message } = refs;
        const { callback, chatType, idKey = 'teleprogram_id' } = options;

        const payload = {
            [idKey]: id.value,
            name: nickname.value,
            message: message.value,
        };

        await axios.post(`/api/v1/chat/${chatType}/${id.value}/`, payload);
        message.value = "";

        if (typeof callback === 'function') {
            await callback();
        };
    })
};