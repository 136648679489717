<template>
        <swiper
          class="radio-slider__slider light-theme"
          :modules="modules"
          :navigation="{
              prevEl: prev,
              nextEl: next,
          }"
          :centeredSlides="true"
          :loop="true"
          :autoplay="autoplay"
          :breakpoints="swiperOptions.breakpoints"
        >
            <!-- <div ref="prev" class="slick-prev slick-arrow">prev</div> -->
                <swiper-slide class="radio-slider__slide" v-for="item in slideShow">
                    <div class="radio-slider__flex">
                        <p class="radio-slider__new" v-for="list in item.tag">{{ list.name }}</p>
                        <p class="radio-slider__name">{{ item.name }}</p>
                        <buttom class="btn__wht" @click="SetRadio(item.radio)">
                          <span>
                            <i18n-t keypath="radioprogram.live.actions.listen.singular" scope="global">...</i18n-t>
                          </span>
                        </buttom>
                    </div>
                    <div class="radio-slider__prev">
                        <img :src="item.get_preview" v-if="item.get_preview">
                        <img src="@/assets/img/Cover_radio.jpg" v-else>
                    </div>
                </swiper-slide> 
            <!-- <div ref="next" class="slick-next slick-arrow">next</div>           -->
        </swiper>
</template>
<style>
    .list-film__slide {
        display: block;
    }
</style>
<script>
  import { ref } from 'vue';
  import axios from 'axios'
  import moment from 'moment';
  import SwiperCore, { Navigation, Autoplay } from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  SwiperCore.use(Autoplay);

  export default {
    name: 'CarouselSwiper',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
          breakpoints: {       
            700: {       
               slidesPerView: 1.7,
               spaceBetween: 5    
            },                                        
          }   
       },
       cat_lists: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      slideShow: {
        type: Array,
        default: []
      }
    },
    methods: {
        async HistoryAddRadio(idc) {
              await axios
              .get(`/api/v1/add/radio/history/${idc}/`)
              .then(response => {
                  console.log('history add')
              })
              .catch(error => {
                console.log(error)
              })           
        },   
        SetRadio(value) {
            const radio = JSON.stringify(value)

            this.$store.commit('setRadio', radio)
            localStorage.setItem("radio", radio)

            this.HistoryAddRadio(value.id)
            this.$store.commit('initializeStore')
        },
    },
    setup() {
       const prev = ref(null);
       const next = ref(null);
      return {
        modules: [Navigation],
        prev,
        next,
      };
    },
    created() {

    }
  };
</script>