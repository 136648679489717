import moment from "moment";
import '@/utils/locale/ruLatnFactory';

export const momentLocale = (lang = 'ru-cyrl') => {
    const locale = lang.toLowerCase().replace('_', '-');

    moment.locale(locale);
};

export const decodeTime = (timeValue, locale = 'ru-cyrl') => {
    momentLocale(locale);

    const time = moment(timeValue, "HH:mm:ss").format("HH:mm");

    return time;
};

export const formatDate = (date, format = 'DD MMMM YYYY', locale = 'ru-cyrl') => {
    momentLocale(locale);

    const dateSort = moment(String(date)).format(format);

    return dateSort;
}