<template>
  <Header />
    <section class="tv-radio">
        <div class="container">
            <div class="tv-radio__radio_wrapper">
                <div class="tv-radio__radio">
                    <p class="tv-radio__radio_link blue active">ТВ</p>
                    <p class="tv-radio__radio_link green" @click="GoTo('/radio/catalog')">Радио</p>
                </div>
            </div>
            <div class="category__row_wrapper">
                <div class="category__row">
                    <a :class="{'active': categoryCurrent == 0}" @click="SelectAllVideo(0)">Все</a>
                    <a 
                        v-for="list, index in categorys" 
                        @click="SelectCategory(list.id, index + 1, list.name)"
                        :class="{'active': categoryCurrent == index + 1}"
                    >
                        {{ list.name }}
                    </a>
                </div>
            </div>
            <TvCatalog 
                v-if="categoryCurrent == 0 && slideShow"
                :slideShow="slideShow" 
                :key="componentKey"
            />
        </div>
    </section>
<!--     <section v-if="categoryCurrent == 0">
        <SwiperCatalog :videos="videosSwiper" />
    </section> -->
    <section class="category">
        <div class="container">
            <div class="cat_flex">
                <h2>{{ categoryName }}</h2>
                <select v-model="selectCat">
                    <option value="0">
                        Сортировка (Не выбрано)
                    </option>
                    <option value="1">
                        Сортировка (Популярное)
                    </option>
                    <option value="2">
                        Сортировка (По новизне)
                    </option>                    
                </select>
            </div>
            <div class="category__tv">
                <router-link v-for="item in videos" :to="'/video/item/' + item.id" class="category__tv_item">
                    <img :src="item.get_previewCatalog" v-if="item.get_previewCatalog">
                    <img src="@/assets/img/Cover_video2.jpg" v-else>
                    <div class="list-video__name">
                        <p>{{ item.name }}</p>
                    </div>
                    <div class="list-film__watch">
                        <p>{{ item.prosmotri }}</p>
                    </div>
                    <div class="list-film__date">
                        <p>{{ CorDate(item.date_added) }}</p>
                    </div>
                    <div class="list-z__bottom"></div>
                </router-link>
            </div>
            <ul class="pagination" v-if="page_max > 0 && page_max != 1">
                <li v-for="list in page_max" v-show="list != page_max">
                    <a @click="setPage(list)" :class="{'active': list == page}">
                        <p>{{ list }}</p>
                    </a>
                </li>
<!--                 <li>
                    <p>...</p>
                </li> -->
                <li>
                    <a 
                        :class="{'active': page_max == page}" 
                        @click="setPage(page_max)">
                        <p>{{ page_max }}</p>
                    </a>
                </li>
            </ul>
        </div>
    </section>
    <Donate />


</template>

<style type="text/css">

.list-video__name {
    position: absolute;
    bottom: 40px;
    width: auto;
        z-index: 1;
}

.list-video__name p {
    padding: 0 14px;
    font-size: 24px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #fff;    
}

.list-film__watch, .list-film__date {
    z-index: 1;
}

.list-z__bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    border-radius: 16px;
    height: 155px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.61) 0%, rgba(0, 0, 0, 0.61) 11.79%, rgba(0, 0, 0, 0.59) 21.38%, rgba(0, 0, 0, 0.57) 29.12%, rgba(0, 0, 0, 0.54) 35.34%, rgba(0, 0, 0, 0.50) 40.37%, rgba(0, 0, 0, 0.46) 44.56%, rgba(0, 0, 0, 0.41) 48.24%, rgba(0, 0, 0, 0.36) 51.76%, rgba(0, 0, 0, 0.31) 55.44%, rgba(0, 0, 0, 0.25) 59.63%, rgba(0, 0, 0, 0.20) 64.66%, rgba(0, 0, 0, 0.15) 70.88%, rgba(0, 0, 0, 0.09) 78.62%, rgba(0, 0, 0, 0.05) 88.21%, rgba(0, 0, 0, 0.00) 100%);
}

@media (max-width: 1024px) {
    .list-video__name p {
        font-size: 16px;
        max-width: 115px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: initial;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }


}


</style>

<script>
import axios from 'axios'
import moment from 'moment';
import Header from '@/components/Header/Header'
import Donate from '@/components/Donate/Donate'
import TvCatalog from '@/components/Swiper/TvCatalog'

import SwiperCatalog from '@/components/Swiper/Catalog/SwiperCatalog'
import { formatDate } from '@/utils/datetime';

export default {
  name: 'MainCatalog',
  data() {
    return {  
        categorys: [],
        videos: [],
        videosSwiper: [],

        categoryName: "Видео",

        categoryCurrent: 0,
        categoryIdc: "",
        selectCat: 0,
        prosmotri: false,
        date_added: "",

        slideShow: [],

        componentKey: true,

        page: 1,
        pageOffset: 30,
        itemsLenght: 0,
        page_max: 0,
    }
  },
  mounted() {
    this.GetSlideShow()
  },
  methods: {
      GoTo(redirect) {
        this.$router.push(redirect)
      },
      async GetCategoryAll() {
          await axios
          .get(`/api/v1/get/all/category/`)
          .then(response => {
              this.categorys = response.data

              console.log(this.categorys)

          })
          .catch(error => {
            console.log(error)
          })           
      },   
      async GetCategoryVideos() {
          var cat = this.categoryIdc
          if(this.categoryCurrent == 0) cat = ''

          await axios
          .get(`/api/v1/get/all/video/category/?category_id=${cat}&prosmotri=${this.prosmotri}&date_added=${this.date_added}&page=${this.page}`)
          .then(response => {
              this.videos = response.data.results
              this.itemsLenght = response.data.count

              this.page_max = Math.round(this.itemsLenght / this.pageOffset)
          })
          .catch(error => {
            console.log(error)
          })           
      },
      async GetSlideShow() {
          await axios
          .get(`/api/v1/get/slideshow/main/True/`)
          .then(response => {
              this.slideShow = response.data
              this.forceRerender()
          })
          .catch(error => {
            console.log(error)
          })           
      }, 
      async GetSwiperVideos() {
          await axios
          .get(`/api/v1/get/all/video/category/?prosmotri=true`)
          .then(response => {
              this.videosSwiper = response.data.results
          })
          .catch(error => {
            console.log(error)
          })           
      },
      async forceRerender() {
        this.componentKey = false; 

        await this.$nextTick();

        this.componentKey = true;  
      },
      SelectAllVideo(num) {
        this.categoryCurrent = num
        this.categoryName = 'Видео'

        if (this.selectCat == 0) {
            this.prosmotri = false
            this.date_added = ""
            this.GetCategoryVideos()
        } 

        if (this.selectCat == 1) {
            this.prosmotri = true
            this.date_added = ""
            this.GetCategoryVideos()
        } 

        if (this.selectCat == 2) {
            this.prosmotri = false
            this.date_added = true
            this.GetCategoryVideos()
        } 

      },  
      setPage(page) {
        this.page = page
        this.GetCategoryVideos()
      },
      SelectCategory(idc, num, name) {
        this.categoryIdc = idc
        this.categoryCurrent = num
        this.categoryName = name
        this.page = 1

        this.GetCategoryVideos()
      },
      CorDate(date) {
        return formatDate(date, 'DD MMMM YYYY', this.$i18n.locale);
      },

  },
  components: {
    Header,
    Donate,
    TvCatalog,
    SwiperCatalog
  },
  created() {
    this.GetCategoryAll()
    this.SelectAllVideo(0)
    // this.GetSwiperVideos()
  },
  watch: {
    selectCat(value) {
        if (this.categoryCurrent == 0) this.SelectAllVideo(0)

        if (this.selectCat == 0) {
            this.prosmotri = false
            this.date_added = ""
            this.GetCategoryVideos()
        } 

        if (this.selectCat == 1) {
            this.prosmotri = true
            this.date_added = ""
            this.GetCategoryVideos()
        } 

        if (this.selectCat == 2) {
            this.prosmotri = false
            this.date_added = true
            this.GetCategoryVideos()
        } 
    },
  },
}
</script>
