<template>
    <div class="player-video_component">
        <div class="container" v-if="loading">
            <div class="player__video video_play">
                <p class="player__video_yers" v-if="video_info.censored != 99">{{ video_info.censored }}+</p>
                <div v-html="video_info.youtubeIframe"></div>
            </div>
        </div>
        <div class="container" v-else>
            <div class="center-loading">
                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
        </div>
    </div>

</template>

<style>
    iframe {
        width: 100% !important;
        height: 550px;
    }

    @media (max-width: 576px) {
        iframe {
            height: 220px !important;
        }
    }
</style>
<script>
  import axios from 'axios'
  import moment from 'moment';

  export default {
    name: 'YoutubeFrame',
    data() {
      return {
        player: null,
        loading: true,
      }
    },
    components: {

    },
    props: {
        video_info: {
          type: Object,
          default: {},
        },
    },
    mounted() {

    },
    methods: {

    },
    created() {

    }
  };
</script>