<script setup>
import { onMounted, getCurrentInstance, nextTick, ref, defineExpose } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import RadioPlayer from '@/components/Radio/RadioPlayer.vue';
import Footer from '@/components/Footer/Footer.vue';

const componentKey = ref(true);
const theme = ref('light');
const radioInfo = ref(null);

const { t } = useI18n();
const store = useStore();
const router = useRouter();
const instance = getCurrentInstance();
const $Progress = instance.appContext.config.globalProperties.$Progress;
const VueProgressBar = instance.appContext.app.component('vue-progress-bar');

const forceRerender = async () => {
    componentKey.value = false;

    await nextTick();

    componentKey.value = true;
}

$Progress.start();

router.beforeEach((to, from, next) => {
    if (to.meta.progress !== undefined) {
        $Progress.parseMeta(to.meta.progress);
    }

    $Progress.start();

    next();
});

router.afterEach(() => {
    $Progress.finish();
});

onMounted(() => {
    $Progress.finish();

    document.title = t('title.singular');

    theme.value = Boolean(store.state.theme);

    radioInfo.value = store.state.radio;
});

store.watch((state) => state.radio, (value) => {
    radioInfo.value = value;
});

store.watch((state) => state.theme, (value) => {
    theme.value = Boolean(value);
});

defineExpose({
    forceRerender,
});

</script>

<style>
@import '@/assets/css/reset.css';
@import '@/assets/fonts/fonts.css';
@import '@/assets/css/slick.css';
@import '@/assets/css/style.css';
@import '@/assets/css/variable.css';
</style>

<template>
    <div class="wrapper" :class="{ 'dark-theme': theme, 'light-theme': !theme }">
        <router-view />
        <Footer />
        <VueProgressBar />

        <RadioPlayer 
            :file="radioInfo.link_radio"
            :radio="radioInfo"
            :auto-play="true"
            v-if="radioInfo && radioInfo.link_radio"
            downloadable
        />
    </div>
</template>