<script lang="js" setup>
import { defineModel, defineProps, ref, toRef, watch, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { getAllMessages, getSendMessage } from "./chat";

const store = useStore();

const props = defineProps({
    className: {
        type: String,
        default: "player",
    },
    id: {
        type: Number,
        default: 0
    },
    idKey: {
        type: String,
        default: "radioprogram_id"
    },
    chatType: {
        type: String,
        default: "radio"
    }
});

const { chatType, idKey } = props;

const messagesInterval = ref(0);
const nickname = ref("");
const messages = ref([]);
const message = defineModel("message", { default: "" });
const nicknameObj = defineModel("nicknameObj", { default: "" });
const chatId = toRef(props, "id");

const saveName = (name) => {
    store.commit('setNickname', name);
    localStorage.setItem('nickname', name);
    store.commit('initializeStore');

    nickname.value = name;
}

const AllMessages = getAllMessages({ id: chatId, messages }, { chatType });
const SendMessage = getSendMessage({ id: chatId, message, nickname }, {
    callback: AllMessages,
    chatType,
    idKey
});

watch(chatId, async (newId, oldId) => {
    if (newId !== oldId) {
        await AllMessages();
    }
});

onMounted(() => {
    nickname.value = store.state.nickname;

    messagesInterval.value = setInterval(async () => await AllMessages(), 2000);
});

onUnmounted(() => {
    clearInterval(messagesInterval.value);
});

</script>

<template>
    <div :class="`${className}__chat`">
        <div :class="`${className}__chat_title`">
            <p>
                <i18n-t keypath="chat.title.singular" scope="global">...</i18n-t>
            </p>
        </div>
        <div :class="`${className}__chat_wrapper`">
            <form class="chat__block" v-if="!nickname" @submit.prevent="saveName(nicknameObj)">
                <div class="dflex">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M14 8C14 11.3137 11.3137 14 8 14C6.81929 14 5.71824 13.659 4.78998 13.07L2 14L2.92999 11.21C2.34104 10.2818 2 9.18071 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8Z"
                           stroke="url(#paint0_linear_516_23700)" stroke-width="1.5" stroke-linecap="round"
                           stroke-linejoin="round" />
                        <path d="M10.6665 8H10.6678V8.00133H10.6665V8Z" stroke="#0066C3" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                        <path d="M8 8H8.00133V8.00133H8V8Z" stroke="#0066C3" stroke-width="2" stroke-linecap="round"
                           stroke-linejoin="round" />
                        <path d="M5.3335 8H5.33483V8.00133H5.3335V8Z" stroke="#0066C3" stroke-width="2"
                           stroke-linecap="round" stroke-linejoin="round" />
                        <defs>
                           <linearGradient id="paint0_linear_516_23700" x1="8" y1="-13.75" x2="8" y2="31.1"
                              gradientUnits="userSpaceOnUse">
                              <stop stop-color="#0080F5" />
                              <stop offset="1" stop-color="#003494" />
                           </linearGradient>
                        </defs>
                    </svg>
                    <p>
                        <i18n-t keypath="chat.caption.singular" scope="global"></i18n-t>
                    </p>
                </div>
                <input type="text" v-model="nicknameObj" :placeholder="$t('chat.placeholder.nickname.singular')" />
                <button type="submit" class="btn__blue">
                    <i18n-t keypath="chat.action.nickname.singular" scope="global"></i18n-t>
                </button>
            </form>
            <p v-for="messageItem in messages"><span>{{ messageItem.name }}</span> {{ messageItem.message }}</p>
        </div>
        <form :class="`${className}__chat_input`" @submit.prevent="SendMessage()">
            <input type="text" v-model="message" :placeholder="$t('chat.placeholder.message.singular')" />
            <button type="submit" :class="{ 'btn__green': className === 'radio', 'btn__blue': className === 'player' }">
                <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M12.6668 6.5L8.00016 11.1667L3.3335 6.5" stroke="#fff" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            </button>
        </form>
    </div>
</template>