<template>
    <section class="donate">
<!--         <div class="container">
            <div class="donate__wrapper">
                <h1>Поддержите Фонд</h1>
                <p class="donate__subtitle">Мы будем благодарны за помощь нашему проекту — ежемесячную или разовую</p>
                <div class="donate__check_wrapper">
                    <div class="donate__check">
                        <span class="active">Ежемесячная</span>
                        <span>Разовая</span>
                    </div>
                </div>
                <div class="donate__form">
                    <input type="text" placeholder="Ваше имя" class="donate__input">
                    <input type="text" placeholder="Email" class="donate__input">
                    <input type="text" placeholder="Сумма ежемесячного пожертвования" class="donate__input">
                    <div class="donate__row">
                        <a href="#">200 ₽</a>
                        <a href="#">500 ₽</a>
                        <a href="#">1000 ₽</a>
                        <a href="#">5000 ₽</a>
                    </div>
                    <div class="donate__label">
                        <input type="checkbox">
                        <div></div>
                        <p>Я согласен(а) с <a href="#">Политикой конфиденциальности</a></p>
                    </div>
                    <button class="donate__btn">Поддержать</button>
                </div>
            </div>
        </div> -->
    </section>
</template>

<style scoped>

</style>

<script>
	export default {
		name: 'Donate',
		props: {

		},
        methods: {
            GoTo(redirect) {
                this.$router.push(redirect)
            },
        },
	}
</script>