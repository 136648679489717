<template>
   <Header />

   <div class="radio-program">
      <div class="container">
         <RadiosNavigation
            :title="$t('radioprogram.title.singular')"
            :radioProgram="radiosFiltering" 
            :current_programm="current_programm.id"
            :indexSwiper="indexSwiper" 
            @active="current_programm = $event" 
         />
         <div class="radio-program__wrapper">
            <div v-for="list in radios" class="radio-program__block" @click="SetRadio(list)"
               :class="{ 'default-cursor': isCursorDefault(list) }">
               <div class="radio-program__trans">
                  <p class="radio-program__trans_text" v-if="list.time_to_start">
                     {{ CorTime(list.time_to_start) }}
                  </p>
                  <p class="radio-program__trans_text" v-else>
                     ***
                  </p>
               </div>
               <div class="radio-program__item">
                  <div class="radio-program__prev">
                     <img v-if="list.radio.get_preview" :src="list.radio.get_preview" alt="">
                     <img v-else src="@/assets/img/Cover_radio.jpg" alt="">
                     <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                           d="M4.36077 1.27343C3.36667 0.57687 2 1.28802 2 2.50188V9.49827C2 10.7121 3.36668 11.4233 4.36078 10.7267L9.36486 7.22028C10.2115 6.62704 10.2115 5.373 9.36484 4.77977L4.36077 1.27343Z"
                           fill="var(--white)" />
                     </svg>
                  </div>
                  <div class="radio-program__info">
                     <p class="radio-program__track">{{ list.radio.name }}</p>
                     <p class="radio-program__name" v-if="list.radio.author">{{ list.radio.author.name }}</p>
                  </div>
                  <p class="radio-program__time">{{ formatds(list.radio.duration) }}</p>
                  <div class="radio-program__overflow">
                     <div class="radio-program__func">
                        <a href="#">
                           <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M6 21H18" stroke="#262F44" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M12 3L12 17" stroke="#262F44" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                              <path d="M17 12L12 17L7 12" stroke="#262F44" stroke-width="2" stroke-linecap="round"
                                 stroke-linejoin="round" />
                           </svg>
                        </a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>

   <section class="donate">

   </section>
</template>

<style lang="scss">
.radio-program__item {
   cursor: pointer;

}

.radio-program__block {
   cursor: pointer;

   &.default-cursor {
      cursor: default;

      .radio-program__item {
         cursor: default;
      }
   }
}
</style>

<script>
import axios from 'axios'
import moment from 'moment';
import Header from '@/components/Header/Header'
import RadiosNavigation from '@/components/Swiper/Radios/RadiosNavigation'

export default {
   name: 'RadioProgramm',
   data() {
      return {
         radiosListing: [],
         radiosFiltering: [],
         radios: [],
         radioProgram: [],
         current_programm: {},

         indexSwiper: 0,
         todayDate: 0,


         isFuture: false,
         isToday: false,
      }
   },
   methods: {
      goTo(redirect) {
         this.$router.push(redirect)
      },
      async GetListing() {
         await axios
            .get(`api/v1/get/listing/radios/`)
            .then(response => {
               this.radiosListing = response.data

               console.log(this.radiosListing)

            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetRadioprogramList() {
         await axios
            .get(`/api/v1/get/radioprogram/list/`)
            .then(response => {
               this.radioProgram = response.data

               this.GetRadioprogramToday()
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetRadioprogramToday() {
         await axios
            .get(`/api/v1/get/radioprogram/today/`)
            .then(response => {
               this.current_programm = response.data

               this.indexSwiper = this.radioProgram.findIndex(item => item.id === this.current_programm.id);
               if (response.data == 'none') {
                  this.current_programm = this.radioProgram[this.radioProgram.length - 1]
                  this.indexSwiper = this.radioProgram.findIndex(item => item.id === this.radioProgram[this.radioProgram.length - 1].id);
               }

               this.GetFiltering(this.indexSwiper)
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetRdToday() {
         await axios
            .get(`/api/v1/get/radioprogram/today/`)
            .then(response => {
               this.todayDate = response.data.id
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetFiltering(value) {
         await axios
            .get(`/api/v1/get/radioprogram/list/filter/${value}/`)
            .then(response => {
               this.radiosFiltering = response.data
               console.log(response.data)
            })
            .catch(error => {
               console.log(error)
            })
      },
      async GetRadioContent(value) {
         if (!value) return;
         await axios
            .get(`/api/v1/get/radioprogram/radios/${value}/`)
            .then(response => {
               this.radios = response.data

               console.log(response.data)

            })
            .catch(error => {
               console.log(error)
            })
      },
      CorTime(timeValue) {
         moment.locale('ru');
         const time = moment(timeValue, "HH:mm:ss").format("HH:mm")
         return time
      },
      async HistoryAddRadio(idc) {
         await axios
            .get(`/api/v1/add/radio/history/${idc}/`)
            .then(response => {
               console.log('history add')
            })
            .catch(error => {
               console.log(error)
            })
      },
      SetRadio(value) {
         if (!this.isFuture && !this.isToday) {
            // if (this.todayDate > value.radioprogram_id) {
            //    const radio = JSON.stringify(value.radio)

            //    this.$store.commit('setRadio', radio)
            //    localStorage.setItem("radio", radio)

            //    this.HistoryAddRadio(value.radio.id)
            //    this.$store.commit('initializeStore')
            // } else {
            //    if (this.todayDate == value.radioprogram_id && value.radio.draft == 0) {
            //       const radio = JSON.stringify(value.radio)

            //       this.$store.commit('setRadio', radio)
            //       localStorage.setItem("radio", radio)

            //       this.HistoryAddRadio(value.radio.id)
            //       this.$store.commit('initializeStore')
            //    }
            //    // else {
            //    //  this.$router.push('/radio')
            //    // }
            // }
            const radio = JSON.stringify(value.radio)

            this.$store.commit('setRadio', radio)
            localStorage.setItem("radio", radio)

            this.HistoryAddRadio(value.radio.id)
            this.$store.commit('initializeStore')
         } else if (this.isToday) {
            if (value.radio.draft == 0) {
               const radio = JSON.stringify(value.radio)

               this.$store.commit('setRadio', radio)
               localStorage.setItem("radio", radio)

               this.HistoryAddRadio(value.radio.id)
               this.$store.commit('initializeStore')
            }
         }

      },
      isCursorDefault(value) {
         if ((!this.isFuture && !this.isToday) || (this.isToday && value.radio.draft == 0)) {
            return false
         } else {
            return true
         }
      },
      // formatds(seconds) {
      //    seconds = parseInt(seconds)
      //    var hourse = Math.floor(moment.duration(seconds, 'seconds').asHours())
      //    if (hourse == 0) return moment.duration(seconds, 'seconds').minutes() + ':' + moment.duration(seconds, 'seconds').seconds();
      //    else return hourse + ':' + moment.duration(seconds, 'seconds').minutes() + ':' + moment.duration(seconds, 'seconds').seconds();
      // },
      formatds(seconds) {
         this.checkMoment(seconds)
         seconds = parseInt(seconds)
         let h = Math.floor(moment.duration(seconds, 'seconds').asHours())
         let m = Math.floor(moment.duration(seconds, 'seconds').minutes())
         let s = Math.floor(moment.duration(seconds, 'seconds').seconds())
         return this.checkMoment(h) + ':' + this.checkMoment(m) + ':' + this.checkMoment(s)
      },
      checkMoment(value) {
         if (value == 0) {
            return '00'
         } else if (value < 10) {
            return '0' + value
         } else {
            return value
         }
      },
   },
   components: {
      Header,
      RadiosNavigation
   },
   mounted() {
      setTimeout(() => {
         this.GetRadioContent(this.current_programm.id)
      }, 500)
   },
   created() {
      this.GetRadioprogramList()
      this.GetRdToday()
   },
   watch: {
      current_programm(value) {
         this.GetRadioContent(this.current_programm.id)


         const tvDate = new Date(value.date_to_start);
         const currentDate = new Date()

         // if (tvDate < currentDate) {
         //    this.isFuture = false
         //    return
         // } else if (tvDate.getFullYear() == currentDate.getFullYear() && tvDate.getMonth() == currentDate.getMonth() && tvDate.getDate() == currentDate.getDate()) {
         //    this.isFuture = false
         //    return
         // } else {
         //    this.isFuture = true
         // }
         if (tvDate < currentDate) {
            this.isFuture = false
            this.isToday = false
            // return
         } else {
            this.isToday = false
            this.isFuture = true
            // return
         }
         if (tvDate.getFullYear() == currentDate.getFullYear() && tvDate.getMonth() == currentDate.getMonth() && tvDate.getDate() == currentDate.getDate()) {
            this.isFuture = false
            this.isToday = true
            // return
         }
      },
   },
   setup() {

   }
}
</script>
