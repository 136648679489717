import axios from "axios";
import { tryCatch } from "@/utils/handle";

export const getSlideShow = (slideShowRef) => async () => {
    await tryCatch(null, async () => {
        const response = await axios.get("/api/v1/get/slideshow/main/True/");

        slideShowRef.value = response.data;
    });
};
