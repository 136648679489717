<template>
    <div class="list-film" v-for="list,index in cat_lists">
        <div class="container">
            <h2>{{ list.name }}</h2>
            <swiper 
                :modules="modules"
                slidesPerView="2"
                :navigation="{
                    prevEl: prev,
                    nextEl: next,
                }"
                :slidesPerGroup="1"
                :autoplay="false"
                :breakpoints="swiperOptions.breakpoints"
                class="list-film__wrapper" 
            >
                <div ref="prev" class="slick-prev slick-arrow">prev</div>
                    <swiper-slide v-for="item in cat_lists[index].videos">
                        <router-link :to="'/video/item/' + item.id" class="list-film__slide">
                            <img :src="item.get_previewCatalog" v-if="item.get_previewCatalog">
                            <img src="@/assets/img/Cover_video2.jpg" v-else>
                            <div class="list-video__name">
                                <p>{{ item.name }}</p>
                            </div>
                            <div class="list-film__watch">
                                <p>{{ item.prosmotri }}</p>
                            </div>
                            <div class="list-film__date">
                                <p>{{ CorDate(item.date_added) }}</p>
                            </div>
                            <div class="list-z__bottom"></div>
                        </router-link>
                    </swiper-slide>
                <div ref="next" class="slick-next slick-arrow">next</div>
            </swiper>
        </div>
    </div>
</template>
<style scoped>
.list-film .swiper-list {
    overflow: visible;
}

.swiper-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;    
}
</style>
<script>
  import { ref } from 'vue';
  import axios from 'axios';
  import SwiperCore, { Navigation, Autoplay } from "swiper";
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { formatDate } from '@/utils/datetime';
  SwiperCore.use(Autoplay);
  import 'swiper/css';
  import 'swiper/css/navigation';

  export default {
    name: 'SwiperCatalog',
    data() {
      return {
        autoplay: {
          delay: 5000,
        },
        swiperOptions: {
          breakpoints: {       
            576: {       
               slidesPerView: 2,
               spaceBetween: 25    
            },
            1024: {       
               slidesPerView: 5,
               spaceBetween: 35    
            },                                        
          }
       },
       cat_lists: [],
      }
    },
    components: {
      Swiper,
      SwiperSlide,
    },
    props: {
      teams: {
        type: Array,
        default: []
      }
    },
    methods: {
        async GetCategoryList() {
          await axios
          .get(`/api/v1/get/main/list/`)
          .then(response => {
              this.cat_lists = response.data
              console.log(response.data)
          })
          .catch(error => {
            console.log(error)
          })           
        },     
        CorDate(date) {
          return formatDate(date, 'DD MMMM YYYY', this.$i18n.locale);
        },
    },
    setup() {
        const swiper = ref(null)
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
       const prev = ref(null);
       const next = ref(null);
      return {
        modules: [Navigation],
        onSwiper,
        prev,
        next,
      };
    },
    created() {
        this.GetCategoryList()
    }
  };
</script>