<template>
    <div>
        <h1 v-if="!isMobile" style="margin-bottom: 40px">{{ title }}</h1>
        <div class="radio-program__slider-wrapper">
            <div class="radio-program__title">
                <h1 v-if="isMobile">{{ title }}</h1>
                <div :class="{ 'radio-program__controls': isMobile }">
                    <div ref="prev" class="slick-prev slick-arrow">prev</div>
                    <div ref="next" class="slick-next slick-arrow">next</div>
                </div>
            </div>
            <swiper 
                :modules="modules" 
                :breakpoints="swiperOptions.breakpoints"
                :initial-slide="indexLive - 1"
                :slidesPerView="3.5"
                :slidesPerGroup="2"
                class="radio-program__slider" 
                :navigation="{
                    prevEl: prev,
                    nextEl: next,
                    disabledClass: 'slick-disabled'
                }"
                mousewheel
                @swiper="onSwiper"
            >
                <swiper-slide v-for="list, index in radioStream" class="radio-program__slider_item" :class="{
                    'active': indexLive == index,
                    'radio-program__slider_dis': 3 > index
                }" @click="SetCurrent(list)">
                    <p class="radio-program__slider_day">{{ DateToday(list.date_to_start) }}</p>
                    <p class="radio-program__slider_date">{{ CorDate(list.date_to_start) }}</p>
                </swiper-slide>
            </swiper>
        </div>
    </div>
</template>

<style lang="scss">
.radio-program__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .radio-program__controls {
        position: relative;
        display: flex;
        align-items: center;
        gap: 0.625rem;

        & .slick-arrow {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 42px;
            height: 42px;
            transform: unset;
            left: unset;
            right: unset;
            opacity: 1;
            font-size: 0;
            background: #82909F4D !important;
            border: none;
            border-radius: 0.75rem;
            transition: all 0.3s;

            &.slick-prev::before {
                transform: rotate(180deg);
            }

            &::before {
                content: "";
                display: block;
                width: 21px;
                height: 21px;
                background: url("@/assets/img/slider_next.svg") center no-repeat;
                background-size: contain;
            }

            &:hover, &:active {
                background: #82909F !important;
                box-shadow: 0px 20px 80px rgba(1, 144, 64, 0.3), inset 0px 1px 0px rgba(255, 255, 255, 0.08);
            }
        }
    }
}

@media (max-width: 720px) {   
    .radio-program__title h1 {
        font-size: 1.75rem;
        line-height: 2.25rem;
        font-weight: 800;
    }
}
</style>

<script lang="js">
import { ref, computed, toRefs, defineComponent, watch } from 'vue';
import SwiperCore, { Navigation, Autoplay, Mousewheel } from "swiper";
import { useI18n } from 'vue-i18n';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import { useScreenSize } from '@/utils/screen';
import { formatDate } from '@/utils/datetime';

SwiperCore.use(Autoplay);

const Disabled = () => {
    console.log('disabled');
}

const autoplay = {
    delay: 5000,
};

const swiperOptions = {
    autoplay,
    breakpoints: {
        700: {
            slidesPerView: 7,
            slidesPerGroup: 1,
            spaceBetween: 15,
        },
    }
}

const cat_lists = [];

export default defineComponent({
    name: 'RadiosNavigation',
    emits: ['active'],
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        radioProgram: {
            type: Array,
            default: [],
        },
        title: {
            type: String,
            default: "",
        },
        current_programm: {
            type: Number,
            default: 0,
        },
        indexSwiper: {
            type: Number,
            default: 0,
        },
    },
    setup(props, { emit }) {
        const { current_programm, radioProgram: radioStream } = toRefs(props);
        const swiper = ref(null);
        const { locale } = useI18n({
            useScope: 'global',
            inheritLocale: true,
        });

        const [isMobile] = useScreenSize();

        const prev = ref(null);
        const next = ref(null);

        const CorDate = (date) => {
            const m = isMobile.value;

            const f = `DD MMM${ m ? '' : 'M' }`;

            const dataSort = formatDate(date, f, locale.value).replace('.', '');

            return dataSort + (m ? '.' : '');
        };

        const DateToday = (value) => {
            const m = isMobile.value;

            const f = `ddd${ m ? '' : 'd' }`;

            const today = formatDate(value, f, locale.value);
            
            return today + (m ? '.' : '');
        }

        const indexLive = computed(() => {
            const v = radioStream.value || [];

            const i = v.findIndex((item) => item.id === current_programm.value);

            return i;
        });

        const onSwiper = (instance) => {
            swiper.value = instance;
        };

        const SetCurrent = (value) => {
            emit('active', value)
        };

        watch(indexLive, (value, oldValue) => {
            if (value === oldValue) return;
            if (!swiper.value) return;

            swiper.value.slideTo(value - 1, 0, true);
        });

        return {
            modules: [Navigation, Mousewheel],
            onSwiper,
            prev,
            next,
            indexLive,
            radioStream,
            Disabled,
            DateToday,
            CorDate,
            autoplay,
            swiperOptions,
            cat_lists,
            SetCurrent,
            isMobile
        };
    }
});
</script>