import { ref, onMounted, onUnmounted } from 'vue';

export const useScreenSize = () => {
    const isMobile = ref(window.innerWidth <= 850);

    const updateScreenSize = () => {
        isMobile.value = window.innerWidth <= 850;
    };

    onMounted(() => {
        window.addEventListener('resize', updateScreenSize);
    });

    onUnmounted(() => {
        window.removeEventListener('resize', updateScreenSize);
    });

    return [isMobile];
};
